<template>
  <div id="app">
    <el-cascader v-model="cascaderArr"
                 class="el-cascader-panel"
                 :placeholder="placeholder"
                 :show-all-levels="showAllLevels"
                 @change="selectCascader"
                 :props="props"
                 :options.sync="options"
                 :size="size"></el-cascader>

  </div>
</template>

<script>
export default {
  props: {
    // 默认显示的值
    defaultData: {
      type: Array,
      default () {
        return [];
      }
    },
    //提示文字
    placeholder: {
      type: String,
      default () {
        return '';
      }
    },
    //是否展示父节点值
    showAllLevels: {
      type: Boolean,
      default () {
        return true;
      }
    },
    //cascader的 props
    props: {
      type: Object,
      default () {
        return {};
      }
    },
    //cascader的 options
    options: {
      type: Array,
      default () {
        return [];
      }
    },
    //cascader的 size
    size: {
      type: String,
      default () {
        return 'small';
      }
    },
  },
  data () {
    return {
      cascaderArr: []
    }
  },
  beforeMount () {
    this.changeDefault()
  },
  watch:{
    defaultData(){
      this.changeDefault()
    }
  },
  methods: {
    selectCascader () {
      const result = []
      const arr = this.cascaderArr
      for (let i in arr) {
        if (arr[i] instanceof Array && arr[i].length > 0) {
          result.push(arr[i][arr[i].length - 1])
        } else {
          result.push(arr[i])
        }
      }

      this.$emit('selectCascader', result)
    },
    changeCascader (key, arrData) {
      let arr = [];
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach (childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].value;
          if (childrenData[j].value == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].children) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(arrData, depth);
    },
    changeDefault(){
      const defaultData = this.defaultData
      let newArr = []
      if (defaultData.length > 0) {
        for (let i in defaultData) {
          // 循环后端返回的数组依次进行对比，添加到newArr 新数组中去
          newArr.push(this.changeCascader(defaultData[i], this.options))
        } // 级联多选回显
        this.$nextTick(()=>{
          this.cascaderArr = newArr
        })
      }else{
         this.cascaderArr = []
      }
    }
  }

}
</script>

<style>
</style>