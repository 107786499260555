import { request } from './request.js'


//地址联动查询
//地址一级
export function getAreaFirst () {
  return request({
    url: '/common/area-first',
		method:'GET'
  })
}

//地址二三四级
export function getAreaCode (params) {
  return request({
    url: '/common/area-code',
		method:'GET',
		params
  })
}
//加载地址（省市选项）
export function getAreaProvinceCity () {
  return request({
    url: '/common/area-province-city',
		method:'GET',
  })
}

//旅游区域-树形数据
export function getTouristArea () {
  return request({
    url: '/common/tour-area',
		method:'GET',
  })
}

//旅游分类-树形数据
export function getTouristType () {
  return request({
    url: '/common/tour-category',
		method:'GET',
  })
}

//商品分类-树形数据
export function getBasisGoodsList () {
  return request({
    url: '/common/goods-category',
		method:'GET',
  })
}

//品牌分类-树形数据
export function getBrandList () {
  return request({
    url: '/common/brand',
		method:'GET',
  })
}