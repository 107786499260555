<template>
  <!--
    form 数据

    formLabel 数据标签  = [{
    model: 模型数据 form[item.model],
    label: 标签名,
    labelOFF: label 标签显示开关 默认:开
    options:[{value:'值',label:'标签'}]
    }]

    默认 type input输入框
    type = select  select下拉框
    type = switch  switch按钮

    下拉框 内部数据 options:[value:'值',label:'标签']

    表单配置
    :inline="true"  表示 行内表单
    label-width="80px"  表单的宽度
-->

  <!-- 表单 -->
  <el-form :inline="inline"
           ref="form"
           :model="form"
           :label-width="labelWidth || '150px'"
           size="small">

    <el-form-item v-for="item in formLabel"
                  class="el-form-item"
                  :key="item.model"
                  :required="item.required || false"
                  v-show="!item.hidden || false"
                  :label="item.labelOFF == 'off' ? '' : item.label"
                  :style="'width:'+item.width + ';' || '100%;'">
      <!-- 输入框 -->
      <el-input v-model.trim="form[item.model]"
                :placeholder="'请输入' + item.label"
                v-if="!item.type"
                :style="'width:'+item.width2 + ';' || '100%;' "
                :class="item.class"
                @blur="blurInput(form[item.model])">
        <template slot="append"
                  v-if="item.tip">{{item.tip}}</template>
      </el-input>

      <!-- 范围 -->
      <div v-if="item.type == 'inputArea'">
        <el-input v-model.trim="form[item.model1]"
                  :placeholder="'请输入'"
                  :style="'width:135px' "
                  :class="item.class"
                  @blur="blurInput(form[item.model1])">
          <template slot="append"
                    v-if="item.tip">{{item.tip}}</template>
        </el-input>
        -
        <el-input v-model.trim="form[item.model2]"
                  :placeholder="'请输入' "
                  :style="'width:135px'"
                  :class="item.class"
                  @blur="blurInput(form[item.model2])">
          <template slot="append"
                    v-if="item.tip">{{item.tip}}</template>
        </el-input>
      </div>

      <!-- 输入框下面有提示 -->
      <div v-if="item.type ==='inputClues'">
        <el-input v-model.trim="form[item.model]"
                  :placeholder="'请输入' + item.label"
                  :style="'width:'+item.width2 + ';' || '100%;' "
                  :class="item.class"
                  @blur="blurInput(form[item.model])">
        </el-input>
        <div slot="tip"
             class="tooltip el-upload__tip">
          {{item.clues}}
        </div>
      </div>

      <!-- 禁用 输入框 -->
      <el-input v-model.trim="form[item.model]"
                v-if="item.type ==='disabled'"
                :disabled="true"
                @blur="blurInput(form[item.model])"></el-input>

      <!-- 文本内容 -->
      <span v-if="item.type ==='text'"
            :style="'width:'+item.width + ';' || '50%;' "
            :class="item.class">
        <span>{{form[item.model]}}</span>
      </span>
      <!-- 退款信息 -->
      <h1 v-if="item.type ==='text1'"
          class="refund">退款信息
      </h1>
      <!-- 商品管理中的‘其他信息’ -->
      <h1 v-if="item.type ==='text2'"
          class="refund1"><span>{{item.label}}</span>
      </h1>

      <!-- textarea输入框 -->
      <el-input type="textarea"
                v-model.trim="form[item.model]"
                rows="3"
                :placeholder="'请输入' + item.label"
                v-if="item.type ==='textarea'"
                @blur="blurInput(form[item.model])"></el-input>

      <!-- tab输入框 -->
      <!-- 可创建条目 -->
      <el-select v-if="item.type === 'tab_create'"
                 v-model.trim="form[item.model]"
                 multiple
                 filterable
                 allow-create
                 default-first-option
                 :placeholder="'请输入'+item.label"
                 @blur="blurInput(form[item.model])"
                 no-data-text="输入标签，按 Enter 添加">
        <el-option v-for="selectItem in item.options"
                   :key="selectItem.value"
                   :label="selectItem.label"
                   :value="selectItem.value">
        </el-option>
      </el-select>
      <!-- 基础多选（不可创建） -->
      <el-select v-if="item.type === 'tab_select'"
                 v-model.trim="form[item.model]"
                 multiple
                 :disabled="item.disabled"
                 :placeholder="'请输入'+item.label">
        <el-option v-for="selectItem in options"
                   :key="selectItem.value"
                   :label="selectItem.label"
                   :value="selectItem.value">
        </el-option>
      </el-select>

      <!-- 密码输入框 -->
      <el-input v-model.trim="form[item.model]"
                :placeholder="'请输入' + item.label"
                v-if="item.type === 'password'"
                show-password></el-input>

      <!-- 单选框 -->
      <el-radio-group v-model="form[item.model]"
                      v-if="item.type === 'radio'">
        <el-radio v-for="radioItem in item.options"
                  :key="radioItem.value"
                  :label="radioItem.value">{{ radioItem.label }}</el-radio>
      </el-radio-group>

      <!-- 复选框-星期 -->
      <el-radio-group v-model="form[item.model]"
                      v-if="item.type === 'week'">
        <el-checkbox v-for="dayItem in item.options"
                     :key="dayItem.id"
                     :label="dayItem.dayweek"
                     @change="modifychecked(dayItem)">{{dayItem.dayweek}}</el-checkbox>
        <el-button type="primary"
                   class="check-screen"
                   @click="clickScreendate()">筛选</el-button>
      </el-radio-group>

      <!-- 复选框-供应商供应类型 -->
      <el-radio-group v-model="form[item.model]"
                      v-if="item.type === 'supplyType'"
                      :style="'width:'+item.width + ';' || '50%;' ">
        <el-checkbox v-for="supplyItem in item.options"
                     :key="supplyItem.id"
                     :label="supplyItem.sType"
                     @change="modifychecked2(supplyItem)">{{supplyItem.sType}}</el-checkbox>
      </el-radio-group>

      <!--select 选择器 -->
      <el-select v-model="form[item.model]"
                 placeholder="请选择"
                 v-if="item.type === 'select'">
        <el-option v-for="smallItem in item.options"
                   :key="smallItem.value"
                   :label="smallItem.label"
                   :value="smallItem.value"></el-option>
      </el-select>

      <!--select 选择器有提示 -->
      <div v-if="item.type === 'selectClues'"
           class="selectClues">
        <el-select v-model="form[item.model]"
                   placeholder="请选择">
          <el-option v-for="smallItem in item.options"
                     :key="smallItem.value"
                     :label="smallItem.label"
                     :value="smallItem.value"></el-option>
        </el-select>
        <div slot="tip"
             class="tooltip el-upload__tip">
          {{item.clues}}
        </div>
      </div>

      <TreeSelect v-if="item.type === 'treeSelect'"
                  :height="item.height?item.height:150"
                  :width="200"
                  size="small"
                  :data.sync="item.options"
                  :defaultProps.sync="item.defaultProps"
                  multiple
                  clearable
                  :collapseTags="item.collapseTags"
                  checkStrictly
                  :nodeKey="item.nodeKey"
                  :checkedKeys="form[item.model]"
                  @popoverHide="function(arrId,arrData){return popoverHide(arrId,arrData,item)}">
      </TreeSelect>

      <!-- Cascader 级联选择器 -->
      <CommonCascader v-if="item.type === 'cascader'"
                      class="el-cascader-panel"
                      :defaultData="form[item.model]"
                      :placeholder="item.placeholder"
                      :showAllLevels="item.showAllLevels"
                      @selectCascader="function(resultArr){ return selectCascader(resultArr, item)}"
                      :props="item.props"
                      :options="item.options"
                      :size="item.size">
      </CommonCascader>

      <!-- Cascader 四级地址联动 级联选择器 -->
      <el-cascader v-model="form[item.model]"
                   ref="cascaderArea"
                   class="el-cascader-panel"
                   v-if="item.type === 'cascaderArea'"
                   :placeholder="item.placeholder"
                   :options="addressOptions"
                   @change="selectAddess( form[item.model], item)"
                   :props="selectAddressConfig"
                   size="medium"></el-cascader>

      <!-- Cascader 省级地址 级联选择器            -->
      <el-cascader v-model="form[item.model]"
                   ref="cascaderArea"
                   v-if="item.type === 'provinceArea'"
                   :placeholder="item.placeholder"
                   :options="addressOptions2"
                   :width="item.width"
                   @focus="getAddress"
                   @change="selectAddess2( form[item.model], item)"
                   :props="selectAddressConfig"
                   size="medium"></el-cascader>

      <!-- Cascader 市级地址 级联选择器            -->
      <el-cascader v-model="form[item.model]"
                   ref="cascaderArea"
                   class="el-cascader-panel-city"
                   v-if="item.type === 'cityArea'"
                   style="margin-left:-110px"
                   :placeholder="item.placeholder"
                   :options="cityAddress"
                   :width="item.width"
                   @focus="getAddress2"
                   @change="selectAddess3( form[item.model], item)"
                   :props="selectAddressConfig"
                   size="medium"></el-cascader>

      <!-- 开关按钮 -->
      <el-switch v-model="form[item.model]"
                 v-if="item.type === 'switch'"
                 active-color="#13ce66"
                 inactive-color="#DCDFE6"
                 :active-value="1"
                 :inactive-value="0"></el-switch>

      <!-- 日期选择器 -->
      <el-date-picker v-model="form[item.model]"
                      type="daterange"
                      value-format="timestamp"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="item.expireTimeOption"
                      :unlink-panels="true"
                      @blur="blurDate(form[item.model])"
                      size="mini"
                      v-if="item.type === 'datePicker'"></el-date-picker>
      <!-- 只有一个时间 -->
      <el-date-picker v-model="form[item.model]"
                      v-if="item.type === 'selectTime'"
                      :style="'width:'+item.width2 + ';' || '100%;'"
                      placeholder="选择日期"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="timestamp">
      </el-date-picker>
      <!-- 日期带时间 -->
      <div class="block"
           v-if="item.type === 'datetime'">
        <el-date-picker v-model="form[item.model]"
                        type="datetime"
                        placeholder="选择日期时间">
        </el-date-picker>
      </div>
      <!-- 日期时间范围 -->
      <div class="block"
           v-if="item.type === 'datetimeArea'">
        <el-date-picker v-model="form[item.model1]"
                        type="datetime"
                        style="width:200px"
                        placeholder="选择日期时间">
        </el-date-picker>
        -
        <el-date-picker v-model="form[item.model2]"
                        style="width:200px"
                        type="datetime"
                        placeholder="选择日期时间">
        </el-date-picker>
      </div>

      <!-- 上传按钮 -->
      <el-upload v-if="item.type === 'upload'"
                 class="avatar-uploader"
                 ref="upload"
                 action="#"
                 accept="image/*"
                 :http-request="function (param) { return uploadFile(param,item)}"
                 :show-file-list="false"
                 :on-preview="handlePreview"
                 :on-error="uploadError">
        <img v-if="form[item.model]"
             :src="form[item.model]"
             class="avatar" />
        <i v-else
           class="el-icon-plus avatar-uploader-icon"></i>
        <div slot="tip"
             class="tooltip el-upload__tip">
          {{item.clues}}
        </div>
      </el-upload>

      <!-- 图片列表缩略图 -->
      <CommonUploadList v-if="item.type === 'uploadList'"
                        :clues="item.clues"
                        :defaultData="form[item.model]"
                        :maxSize="item.maxSize"
                        :Height="item.imgHeight"
                        :Width="item.imgWidth"
                        v-bind="$attrs"
                        v-on="$listeners"
                        @handlePreview="handlePreview"
                        @uploadSuccess="function (url){return uploadSuccess(item, url)}">
      </CommonUploadList>

      <!-- 图片展示 -->
      <el-image class="el-image"
                v-if="item.type === 'image'"
                :src="form[item.model]"
                style="width:100px;height:100px;">
        <div slot="error"
             class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <!-- 带按钮的input -->
      <div v-if="item.type === 'inputButton'">
        <el-input v-model="form[item.model]"
                  :placeholder="'请获取' + item.label"
                  :disabled="true"
                  @blur="blurInput(form[item.model])">
          <el-button slot="append"
                     @click="clickInputBtn()">{{item.name}}</el-button>
        </el-input>
      </div>

      <!-- 分割线 -->
      <el-divider v-if="item.type === 'divider'"
                  content-position="left">{{item.label}}</el-divider>
      <!-- 服务与设备 -->
      <div v-if="item.type == 'tabSelect'">
        <el-input :placeholder="'请输入'+item.label"
                  v-model="Equip"
                  class="input-with-select">
          <el-button slot="append"
                     @click="clickToAddEquip(Equip)">添加</el-button>
        </el-input>
        <div slot="tip"
             class="tooltip el-upload__tip">
          {{item.clues}}
        </div>
        <div class="tagitem">
          <div v-for="tagItem in form[item.model]"
               :key="tagItem.name"
               class="tagitem-flex">
            <div class="tagitem-item"
                 v-if="tagItem.name != undefined">
              <p class="tagitem-item-font">{{tagItem.name+":"}}</p>
              <el-select v-model="tagItem.data"
                         class="tagitem-item"
                         multiple
                         filterable
                         allow-create
                         default-first-option
                         size="medium"
                         :placeholder="item.placeholder"
                         no-data-text="输入标签，按 Enter 添加">
              </el-select>
              <el-button type="danger"
                         class="tagitem-item-btn"
                         icon="el-icon-delete"
                         style="margin-left:15px"
                         @click="handleDelete(tagItem, form[item.model])"
                         circle></el-button>
            </div>
          </div>
        </div>
      </div>
    </el-form-item>

    <el-form-item>
      <slot></slot>
    </el-form-item>
  </el-form>
</template>

<script>
import { uploadFile } from '../api/aliyunOss'
import { getAreaFirst, getAreaCode } from 'api/basisDate.js'
import TreeSelect from './CommonSelectTree.vue'
import CommonCascader from './CommonCascader.vue'
import CommonUploadList from './CommonUploadList.vue'
export default {
  props: {
    inline: Boolean,
    form: Object,
    formLabel: Array,
    labelWidth: String, //字段名的宽度
  },
  components: {
    TreeSelect,
    CommonCascader,
    CommonUploadList
  },

  data () {
    return {
      specName: [],//商品规格
      Equip: '',
      wangeDitorConfig: {
        height: '300px',
        focus: true,
        placeholder: '请输入酒店(民宿)简介'
      },
      fileList: [],//upload组件的数据
      datechecked: [],//存放复选框-星期对象
      supplyed: [],//存放复选框-供应类型
      addressOptions: [],//Cascader 可选项数据源 
      addressOptions2: [],//Cascader省级中 可选项数据源
      cityAddress: [],//Cascader市级级中 可选项数据源
      areaName: [],//存放地址数据
      areaID: [],//存放地址ID
      selectAddressConfig: {//Cascader prop选项
        lazy: true,
        // checkStrictly: true,
        lazyLoad (node, resolve) {
          if (node.data != undefined)
            getAreaCode({
              code: node.data.code
            })
              .then((res) => {
                const nodes = res.data.data.map((item) => ({
                  value: item.id,
                  code: item.code,
                  label: item.name,
                  leaf: item.level >= 3
                }));
                resolve(nodes);
              });
        },
      },

    }
  },
  created () {

    if (this.form != undefined && this.form.hasOwnProperty('area')) {
      this.getAreaFirst();
    }
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeupdate () {
  },
  update () {
  },
  computed () {
  },
  watch: {
    form: function () {
      if (this.form != undefined && this.form.hasOwnProperty('area')) {
        this.getAreaFirst();

      }
    },
  },
  computed: {
  },
  methods: {

    //上传图片
    async uploadFile (param, item) {
      console.log(param, item)
      return new Promise((resolve, reject) => {
        const image = param.file
        const isSzie = image.size / 1024 < item.maxSize || 100;
        console.log(isSzie)
        if (!isSzie) {
          param.onError("上传图片大小不能超过 " + item.maxSize || 100 + "KB!")
          return;
        }
        //调用[限制图片尺寸]函数

        this.limitFileWH(item.imgHeight, item.imgWidth, image).then(async (res) => {
          const isFlag = res;
          console.log(item.imgHeight)
          if (isFlag) {
            await uploadFile(image).then((res) => {
              this.uploadSuccess(item, res)
            })
            return resolve(item, res);
          } else if (item.imgWidth > 900 && item.imgHeight > 600) {
            return reject('上传失败');
          }
        });
      })
    },
    // getAddress3 () {
    //   console.log(333)
    //   this.getAreaFirst()
    // },
    //地址选择完成 -- 返回数据
    selectAddess (id, item) {
      let areaLabel = this.$refs.cascaderArea[0].getCheckedNodes()[0].pathLabels.join('')
      if (id) {
        this.form[item.area.area1] = id[0]
        this.form[item.area.area2] = id[1]
        this.form[item.area.area3] = id[2]
        // this.form[item.area.area4] = id[3] != undefined ? id[3] : 0 //假如三级地址，默认最后一级id为"0"
        this.form.areaLabel = areaLabel
      }
      this.areaName = this.$refs.cascaderArea[0].getCheckedNodes()[0].pathLabels
      this.areaID = id
      console.log(this.areaName, this.areaID)
    },

    //当只有省级地址
    getAddress () {
      this.getAreaFirst2();
    },
    //挑选市级
    getAddress2 () {
      this.selectAddess2()
    },
    //当只有省级地址时， 地址选择完成 -- 返回数据
    selectAddess2 (id, item) {
      let city_code
      this.addressOptions2.forEach((item) => {
        if (item.value == id) {
          city_code = item.code
        }
      })
      if (id) {
        this.form[item.area1] = id[0]
      }
      console.log(this.form)
      getAreaCode({ code: city_code }).then((res) => {
        let array = []
        const DATA = res.data
        if (DATA.code == 200) {
          this.cityAddress = DATA.data.map((area) => {
            return {
              value: area.id, //地址ID
              label: area.name, //地址名称
              code: area.code, //地址的Code
              level: area.level,
              leaf: area.level >= 2,
              children: []
            }

          });
        }
      })
    },
    //有市级地址时， 地址选择完成 -- 返回数据
    selectAddess3 (id, item) {
      if (id) {
        this.form[item.area2] = id[0]
      }
    },
    //treeSelect 事件有两个参数：第一个是所有选中的节点ID，第二个是所有选中的节点数据
    popoverHide (arrId, arrData, item) {
      console.log(arrId, arrData);

      this.form[item.model] = arrId;
    },
    //级联选择器
    selectCascader (arr, item) {
      this.form[item.model] = arr;
    },
    //失去焦点 自动保存
    blurInput (val) {
      this.$emit('blurInput', val)
    },
    focus33 (data) {
      console.log(data)
    },
    //图片上传失败操作
    uploadError (err) {
      this.$message.error(err);
    },
    //图片上传成功操作
    uploadSuccess (item, url) {
      this.$message({
        message: '图片上传成功',
        type: 'success'
      })
      console.log(item, url)
      console.log(this.form)
      if (item.type === 'upload') {
        this.form[item.model] = url;
      } else if (item.type === 'uploadList') {
        this.form[item.model].push(url)
      } else {
        console.log("出错了！")
      }
    },

    //移除文件前的钩子
    beforeRemove (file) {
      return this.$confirm('确定移除' + file.name);
    },
    // //移除文件钩子
    // handleRemove (file) {
    //   this.fileList.splice(this.fileList.indexOf(file.name), 1)
    // },
    // handleRemove (data) {
    //   this.fileList = data
    //   this.$emit('gethandleRemove', this.fileList)
    // },
    //点击放大图片
    handlePreview (file) {
      this.$emit('handlePreview', file)
      // console.log(this.fileList)
    },
    //获得地图一级标签
    async getAreaFirst () {
      getAreaFirst().then((res) => {
        const DATA = res.data;
        const areaArr = this.form.area
        let array = [];
        if (DATA.code == 200) {
          this.addressOptions = DATA.data.map((area) => {
            return {
              value: area.id, //地址ID
              label: area.name, //地址名称
              code: area.code, //地址的Code
              level: area.level,
              leaf: area.level >= 3,
              children: []
            }
          });
          //地址四级 根据地址id 递归回显
          if (typeof (areaArr) != "undefined") {
            this.addressOptions.forEach(async (arrItem) => {
              if (arrItem.value == areaArr[0]) {
                arrItem.children = await this.getAreaCode(arrItem.code)
                arrItem.children.forEach(async (arrItem) => {
                  if (arrItem.value == areaArr[1]) {
                    arrItem.children = await this.getAreaCode(arrItem.code)
                    arrItem.children.forEach(async (arrItem) => {
                      if (arrItem.value == areaArr[2]) {
                        arrItem.children = await this.getAreaCode(arrItem.code)
                        //假如地址只有三级的话，最后一级默认0，方便回显
                        // if (arrItem.children.length == 0) {
                        //   arrItem.leaf = false
                        //   arrItem.children = [{
                        //     value: 0,
                        //     label: '',
                        //     level: 4,
                        //     leaf: true
                        //   }]

                        // }
                      }
                    })
                  }
                })
              }
            })
          }
        }
      })
    },
    //当只需要省级时地图标签时
    async getAreaFirst2 () {
      getAreaFirst().then((res) => {
        const DATA = res.data;
        const areaArr = this.form.area
        let array = [];
        if (DATA.code == 200) {
          this.addressOptions2 = DATA.data.map((area) => {
            return {
              value: area.id, //地址ID
              label: area.name, //地址名称
              code: area.code, //地址的Code
              level: area.level,
              leaf: area.level >= 1,
              children: []
            }
          });
          console.log(this.addressOptions2)
          //地址四级 根据地址id 递归回显
          if (typeof (areaArr) != "undefined") {
            this.addressOptions2.forEach(async (arrItem) => {
              if (arrItem.value == areaArr[0]) {
                arrItem.children = await this.getAreaCode(arrItem.code)
                arrItem.children.forEach(async (arrItem) => {
                  if (arrItem.value == areaArr[1]) {
                    arrItem.children = await this.getAreaCode(arrItem.code)
                    arrItem.children.forEach(async (arrItem) => {
                      if (arrItem.value == areaArr[2]) {
                        arrItem.children = await this.getAreaCode(arrItem.code)
                      }
                    })
                  }
                })
              }
            })
          }
        }
      })
    },
    getAreaCode (area_code) {
      const _this = this
      return new Promise(resolve => {
        let array = [];
        getAreaCode({ code: area_code }).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            array = DATA.data.map((area) => {
              return {
                value: area.id, //地址ID
                label: area.name, //地址名称
                code: area.code, //地址的Code
                level: area.level,
                leaf: area.level >= 3,
                children: []
              }

            });
            resolve(array)
          }
        })

      })

    },
    //添加服务、设备
    clickToAddEquip (data) {
      if (!data) {
        return;//当输入框为空 不执行
      }
      this.$emit('clickToAddEquip', data)
      this.Equip = '';//初始化
    },
    //删除服务、设备
    handleDelete (tag, item) {
      item.splice(item.indexOf(tag), 1);
    },
    //带Input确认按钮 点击事件
    clickInputBtn () {
      this.$emit('clickInputBtn')
    },
    //传递日期信息
    blurDate (date) {
      this.$emit('blurDate', date)
    },
    //限制图片尺寸
    limitFileWH (E_width, E_height, file) {
      let _this = this;
      let imgWidth = "";
      let imgHight = "";
      const isSize = new Promise(function (resolve, reject) {
        let width = E_width;
        let height = E_height;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          imgWidth = img.width;
          imgHight = img.height;

          let valid = img.width <= width && img.height <= height;
          valid ? resolve() : reject();
        }
        img.src = _URL.createObjectURL(file);
      }).then(() => { return true; }, () => {

        if (imgWidth > 900 && imgHight > 600) {
          _this.$message.warning({
            message: '上传文件的图片大小不合符标准,宽需要为' + E_width + 'px，高需要为' + E_height + 'px。当前上传图片的宽高分别为：' + imgWidth + 'px和' +
              imgHight + 'px',
            btn: false
          })
          return false;
        }

      });
      return isSize
    },
    //复选框-星期事件
    modifychecked (dayItem) {
      //输入并传递复选框对象
      this.datechecked.push({
        dateId: dayItem.id,
        dateValue: event.target.value,
        dateWhether: event.target.checked
      })
      this.$emit('modifychecked', this.datechecked)

    },
    //复选框-供应类型事件
    modifychecked2 (supplyItem) {
      this.supplyed.push({
        supplyItemId: supplyItem.id,
        supplyItemWhether: event.target.checked
      })
      for (var i = 0; i < this.supplyed.length; i++) {
        if (this.supplyed[i].supplyItemWhether == false) {
          var aa = this.supplyed[i].supplyItemId
          var rr = 0
          this.supplyed.splice(i, 1);
          for (var j = 0; j < this.supplyed.length; j++) {
            if (this.supplyed[j].supplyItemId == aa) {
              rr = j;
              break;
            }
          }
          this.supplyed.splice(rr, 1)
        }
      }
      this.$emit('modifychecked2', this.supplyed)
    },
    //筛选按钮 点击事件
    clickScreendate () {
      this.$emit('clickScreendate')
    }
  }

}
</script>

<style lang="scss" scoped>
.el-form {
  overflow: hidden;
}
.el-form-item {
  display: inline-block;
  .avatar-uploader {
    display: flex;
    /deep/ .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin-right: 10px;
    }
    .tooltip {
      flex: 1;
      align-self: center;
    }
  }
}

.el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 20px;
  color: #8c939d;
  width: 64px;
  height: 64px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-cascader-panel {
  width: 300px;
}
/deep/.el-cascader-panel-city {
  margin-left: 10px;
}
.wangedito {
  width: 100%;
}
/deep/.input-with-select {
  width: 550px;
  .tagitem-flex {
    .tagitem-item {
      margin-top: 10px;
      .tagitem-item-font {
        font-weight: bold;
        margin: auto 0px;
      }
      .tagitem-item {
        min-width: 70%;
        position: relative;
      }
      .tagitem-item-btn {
        position: absolute;
        margin-top: 20px;
      }
    }
  }
}
/deep/ .tagitem {
  width: 100%;
  margin: 20px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
/deep/ .el-upload--picture-card {
  width: 64px;
  height: 64px;
}
/deep/ .el-upload {
  width: 64px;
  height: 64px;
  line-height: 64px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  height: 64px;
  line-height: 64px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 64px;
  height: 64px;
  line-height: 64px;
}
/deep/ .avatar {
  width: 64px;
  height: 64px;
}
.el-upload__tip {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #909399;
  line-height: 16px;
}
.selectClues {
  position: relative;
}
.selectClues .el-upload__tip {
  position: absolute;
}
.el-switch__core {
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
.check-screen {
  width: 48px;
  height: 24px;
  padding: 0px;
  margin-left: 15px;
}
.range {
  margin-left: -110px;
}
.refund {
  height: 40px;
  width: 700px;
  padding-left: 20px;
  margin-left: -130px;
  background-color: #f8f7f7;
  font-size: 15px;
}
.refund1 {
  height: 40px;
  width: 1550px;
  // padding-left: 20px;
  margin-left: -150px;
  background-color: #f8f7f7;
  font-size: 15px;
  position: relative;
  span {
    position: absolute;
    left: 20px;
  }
}
</style>
