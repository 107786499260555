<template>
  <el-upload class="upload-list"
             action="#"
             accept="image/*"
             :http-request="function (param) { return uploadFile(param)}"
             :on-error="uploadError"
             :on-preview="handlePreview"
             :on-remove="handleRemove"
             :file-list="fileList"
             list-type="picture-card"
             multiple>
    <i class="el-icon-plus avatar-uploader-icon"></i>
    <div slot="tip"
         class="el-upload__tip">{{clues}}</div>
  </el-upload>

</template>

<script>
import { uploadFile } from '../api/aliyunOss'
export default {
  props: {
    // 默认显示的值
    defaultData: {
      type: Array,
      default () {
        return [];
      }
    },
    //限制大小
    maxSize: {
      type: Number,
      default () {
        return 100;
      }
    },
    //限制图片尺寸 -- 高度
    Height: {
      type: String,
      // default () {
      //   return 900;
      // }
    },
    //限制图片尺寸 -- 宽度
    Width: {
      type: String,
      // default () {
      //   return 600;
      // }
    },
    // 提示文字
    clues: {
      type: String,
      default () {
        return '';
      }
    },
  },
  data () {
    return {
      fileList: [],
      imgarr: [],
    }
  },
  watch: {
    defaultData (newData, oldData) {
      if (this.defaultData.length != 0) {
        this.fileList = this.defaultData.map((item, index) => {
          return {
            name: index,
            url: item
          }
        })
      } else {
        this.fileList = []
      }
    }
  },
  beforeMount () {

    if (this.defaultData.length != 0) {
      this.fileList = this.defaultData.map((item, index) => {
        return {
          name: index,
          url: item
        }
      })
    } else {
      this.fileList = []
    }
  },
  methods: {
    //上传图片
    async uploadFile (param) {
      return new Promise((resolve, reject) => {
        const _this = this
        const image = param.file
        const isSzie = image.size / 1024 < _this.maxSize;

        if (!isSzie) {
          param.onError("上传图片大小不能超过 " + _this.maxSize + "KB!")
          return;
        }
        //调用[限制图片尺寸]函数
        this.limitFileWH(_this.Height, _this.Width, image).then(async (res) => {
          const isFlag = res;
          // console.log(res);

          if (isFlag) {
            await uploadFile(image).then((res) => {
              this.uploadSuccess(res, image)
              return resolve(res, image);
            })
          } else {
            return reject('上传失败');
          }
        });
      })

    },
    //限制图片尺寸
    limitFileWH (E_width, E_height, file) {
      let _this = this;
      let imgWidth = "";
      let imgHight = "";
      const isSize = new Promise(function (resolve, reject) {
        let width = E_width;
        let height = E_height;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          imgWidth = img.width;
          imgHight = img.height;
          let valid = img.width <= width && img.height <= height;
          valid ? resolve() : reject();
        }
        img.src = _URL.createObjectURL(file);
      }).then(() => {
        return true;
      }, () => {
        _this.$message.warning({
          message: '上传文件的图片大小不合符标准,宽需要为' + E_width + 'px，高需要为' + E_height + 'px。当前上传图片的宽高分别为：' + imgWidth + 'px和' +
            imgHight + 'px',
          btn: false
        })
        return false;
      });
      return isSize
    },
    //图片上传失败操作
    uploadError (err) {
      this.$message.error(err);
    },
    //图片上传成功操作
    uploadSuccess (url, image) {
      this.$emit('uploadSuccess', url, image)
    },
    //点击放大图片
    handlePreview (file) {
      console.log(file)
      this.$emit('handlePreview', file)
    },
    //移除文件前的钩子
    beforeRemove (file) {
      return this.$confirm('确定移除' + file.name);
    },
    //移除文件钩子
    handleRemove (file) {
      var index = this.fileList.findIndex(function (item) {
        return item.name == file.name
      })
      console.log(index)
      this.fileList.splice(index, 1)
      this.$emit('handleRemove', index)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>